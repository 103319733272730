import React, { useContext } from 'react';
import WarningOverlay from '../WarningOverlay';
import { ScreenEnum } from '../../enums';
import AppStateContext from '../../AppStateProvider/context';
import { complete } from '../../utils/complete';
import { redirect } from '../../utils/redirect';

export default function WarningStartingCheck(props: {
	timeOk: number | boolean | null;
	setTimeOk: React.Dispatch<React.SetStateAction<number | boolean | null>>;
	offline: boolean;
	setOffline: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { state, dispatch } = useContext(AppStateContext);
	const params = new URLSearchParams(window.location.search);
	const apk = params.get('apk') === 'true';
	const texts = props.offline
		? [
				'DID could not connect to the Internet. Please check your internet connection and try again.',
		  ]
		: props.timeOk !== false && apk
		? [
				'The difference between device time and the time server is too large. Please fix the date and time in the settings.',
		  ]
		: [
				'The difference between device time and the time server is too large. Please fix the date and time in the settings and reload this page.',
		  ];

	return (
		<WarningOverlay
			headline={'Warning'}
			texts={texts}
			btnFunction={() => {
				if (
					props.offline &&
					params.get('redirect') !== 'false' &&
					complete(state)
				) {
					redirect(state.brand);
				} else if (apk) {
					dispatch({
						type: 'SET_SCREEN',
						value: ScreenEnum.exitNoCancel,
					});
				} else {
					props.setTimeOk(true);
					props.setOffline(false);
				}
			}}
			okBtn={apk && true}
		/>
	);
}
