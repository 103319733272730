export const PRECACHE_NAME = 'did-setup-webapp-precache';
export const SW_CACHE_READY = 'sw-cache-ready';

export function registerSW() {
	navigator.serviceWorker
		.register('./serviceWorker.js')
		.then(res => {
			console.log('service worker registered');
			const data = {
				type: 'CACHE_URLS',
				precacheName: PRECACHE_NAME,
				payload: Array.from(
					new Set(
						[
							...performance
								.getEntriesByType('resource')
								.map(r => r.name)
								.filter(name =>
									name.includes(window.location.origin)
								),
							'/',
							'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
						].map(url => url.replace(window.location.origin, ''))
					)
				),
			};
			if (res.installing) {
				res.installing.postMessage(data);
			}
		})
		.catch(err => console.log('service worker not registered', err));

	navigator.serviceWorker.addEventListener('message', event => {
		localStorage.setItem(SW_CACHE_READY, event.data.msg);
	});
}
