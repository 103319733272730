import { ScreenEnum } from '../../enums';
import React, { useContext, useState } from 'react';
import AppStateContext from '../../AppStateProvider/context';
import { useTranslation } from 'react-i18next';
import PasswordInput from '../../components/Input/passwordInput';
import './styles.scss';
import { Info } from '../../assets/Icons';
import { checkPW } from '../../utils/checkPW';

export function Password(props: {
	backBtn: Function;
	cancelBtn: JSX.Element;
	setScreen: (next: ScreenEnum) => void;
}) {
	const { state, dispatch } = useContext(AppStateContext);
	const { t } = useTranslation();
	const [oldPw, setOldPw] = useState<string | false>('');
	const [newPw, setNewPw] = useState<string | false>('');
	const [confirmPw, setConfirmPw] = useState<string | false>('');
	const regEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)');

	return (
		<>
			<div className='top less-padding'>
				{props.backBtn(ScreenEnum.selectDevice)}
				<div className='password'>
					<h1>{t('PasswordHeadline')}</h1>
					<p className='p1'>{t('PasswordText')}</p>
					{state.userPassword && (
						<PasswordInput
							labelId='label-old-pw'
							labelHeadline={t('PasswordLabelOld')}
							errorMessage={
								oldPw === false
									? t('PasswordErrorOld')
									: undefined
							}
							value={oldPw ? oldPw : ''}
							id='old-pw'
							placeholder={t('PasswordPlaceholderOld')}
							onInput={e =>
								setOldPw((e.target as HTMLInputElement).value)
							}
						/>
					)}
					<PasswordInput
						labelId='label-new-pw'
						labelHeadline={t('PasswordLabelNew')}
						errorMessage={
							newPw === false ? t('PasswordErrorNew') : undefined
						}
						value={newPw ? newPw : ''}
						id='new-pw'
						placeholder={t('PasswordPlaceholderNew')}
						onInput={e =>
							setNewPw((e.target as HTMLInputElement).value)
						}
					/>
					<PasswordInput
						labelId='label-confirm-pw'
						labelHeadline={t('PasswordLabelConfirm')}
						errorMessage={
							confirmPw === false
								? t('PasswordErrorConfirm')
								: undefined
						}
						value={confirmPw ? confirmPw : ''}
						id='confirm-pw'
						placeholder={t('PasswordPlaceholderConfirm')}
						onInput={e =>
							setConfirmPw((e.target as HTMLInputElement).value)
						}
					/>
					<p className='p2 info'>
						{Info}
						{t('PasswordInfoText')}
					</p>
				</div>
			</div>
			<div className='buttons'>
				<button
					className='white'
					disabled={
						(state.userPassword && !oldPw) ||
						!newPw ||
						!confirmPw ||
						(state.userPassword && oldPw && oldPw.length === 0) ||
						newPw.length < 8 ||
						confirmPw.length < 8
					}
					onClick={() => {
						if (
							state.userPassword === null ||
							checkPW(oldPw || '', state)
						) {
							if (newPw === confirmPw) {
								if (
									newPw &&
									regEx.test(newPw) &&
									newPw.length >= 8
								) {
									dispatch({
										type: 'SET_USER_PASSWORD',
										value: newPw,
									});
									props.setScreen(ScreenEnum.final);
								} else {
									// does not match regex
									setNewPw(false);
								}
							} else {
								// does not match confirm pw
								setConfirmPw(false);
							}
						} else {
							// wrong old pw
							setOldPw(false);
						}
					}}
				>
					{t('PasswordSave')}
				</button>
				<button
					className='black'
					onClick={() => props.setScreen(ScreenEnum.final)}
				>
					{t('Skip')}
				</button>
				{props.cancelBtn}
			</div>
		</>
	);
}
