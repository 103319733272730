export function getUrl(
	market: { name: string; ending: string; currency: string } | null,
	kvps: string | false | null,
	brand: string | null,
	url: string | null | false
) {
	if (url) {
		if (url.indexOf('/') === url.length - 1) {
			url = url.substring(0, url.length - 1);
		}
		return url.includes('https://') ? url : 'https://' + url;
	}

	return `https://${
		market?.name.toLowerCase().split(' ')[0]
	}${kvps}.${brand}-${market?.ending}.did.b12-ssp.de`; // https://deu1234.vw-de.did.b12-ssp.de
}
