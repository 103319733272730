export function getCookie(name: string) {
	function escape(s: string) {
		return s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1');
	}
	var match = document.cookie.match(
		RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
	);

	let cookie;

	if (match) {
		cookie = JSON.parse(match[1]);
		cookie.password = cookie.password
			? cookie.password
					.replaceAll('#', ';')
					.split('')
					.map((c: string) =>
						String.fromCharCode(c.charCodeAt(0) + 13)
					)
					.join('')
			: null;
		cookie.userPassword = cookie.userPassword
			? cookie.userPassword
					.replaceAll('#', ';')
					.split('')
					.map((c: string) =>
						String.fromCharCode(c.charCodeAt(0) + 13)
					)
					.join('')
			: null;
	}

	return match ? cookie : null;
}
