import React, { useContext, useEffect, useState } from 'react';
import { LaunchProps } from './interfaces';
import { ScreenEnum } from '../../enums';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import AppStateContext from '../../AppStateProvider/context';
import SoftwareCheck from '../SoftwareCheck';
import WarningStartingCheck from '../../components/WarningStartingCheck';
import { setLanguage } from '../../utils/setLanguage';
import { updateCache } from '../../utils/updateCache';

export default function Launch(props: LaunchProps) {
	const [animationDone, setAnimationDone] = useState<boolean>(false);
	const [downloadDone, setDownloadDone] = useState<Array<string>>([]);
	const [timeOk, setTimeOk] = useState<null | boolean | number>(null);

	const { state, dispatch } = useContext(AppStateContext);
	const { i18n } = useTranslation();

	// setting root font size to scale everything with the window height
	const root = document.querySelector(':root') as HTMLElement | null;
	if (root) {
		root.style.fontSize = `${window.innerHeight / 1920}px`;
	}

	setTimeout(() => setAnimationDone(true), 20000);

	function checkTime(
		dateString: string | null,
		minDiff: number,
		maxDiff: number
	): number | boolean {
		if (!dateString) return false;
		const diff =
			(Date.now() - new Date(dateString).getTime()) /
			(24 * 60 * 60 * 1000);
		if (diff > minDiff && diff < maxDiff) return true;
		return diff;
	}

	useEffect(() => {
		fetch('./build-info.json', {
			headers: {
				'Cache-Control': 'max-age=0 no-cache',
				'Service-Worker': 'false',
			},
		})
			.then(async r => {
				const infoJsonNew =
					r.headers.get('Content-Type') === 'application/json'
						? ((await r.json()) as { timestamp: string })
						: null;
				const infoJsonOld = localStorage.getItem('build-info')
					? (JSON.parse(localStorage.getItem('build-info') || '') as {
							timestamp: string;
					  })
					: null;
				localStorage.setItem('build-info', JSON.stringify(infoJsonNew));

				if (
					'serviceWorker' in navigator &&
					infoJsonNew &&
					infoJsonOld &&
					new Date(infoJsonNew.timestamp) >
						new Date(infoJsonOld.timestamp)
				) {
					updateCache();
				}
				const checkByInfoJson =
					infoJsonNew !== null &&
					checkTime(
						infoJsonNew.timestamp,
						-7,
						10 * 365 // ~10 years in days,
					);
				if (checkByInfoJson === true) {
					setTimeOk(checkTime(r.headers.get('Date'), -7, 7));
				} else if (checkByInfoJson) {
					setTimeOk(checkByInfoJson);
				} else {
					setTimeOk(false);
				}
			})
			.catch(() => dispatch({ type: 'SET_OFFLINE', value: true }));
	}, []);

	useEffect(() => {
		if (downloadDone.length === 0) {
			state.dataJson.languages.forEach((lang: any) => {
				setLanguage(
					lang.value,
					i18n,
					lang =>
						setDownloadDone(prevState =>
							prevState.includes(lang)
								? prevState
								: [...prevState, lang]
						),
					lang =>
						setDownloadDone(prevState =>
							prevState.includes(lang)
								? prevState
								: [...prevState, lang]
						)
				);
			});
		}
	}, [timeOk]);

	useEffect(() => {
		if (
			!state.softwareCheck &&
			animationDone &&
			downloadDone.length === state.dataJson.languages.length &&
			timeOk === true
		) {
			props.setScreen(ScreenEnum.welcome);
		}
	}, [timeOk, animationDone, downloadDone]);

	if (state.softwareCheck)
		return (
			<SoftwareCheck
				backBtn={props.backBtn}
				setScreen={props.setScreen}
				checked={
					downloadDone.length === state.dataJson.languages.length
						? true
						: undefined
				}
				warning={
					(state.offline || (timeOk !== null && timeOk !== true)) &&
					downloadDone ? (
						<WarningStartingCheck
							timeOk={timeOk}
							setTimeOk={setTimeOk}
							offline={state.offline}
							setOffline={val =>
								dispatch({
									type: 'SET_OFFLINE',
									value: val === true,
								})
							}
						/>
					) : undefined
				}
			/>
		);

	return (
		<div className='top'>
			<div className='animation'>
				<img
					src='./DID_SetupApp_Placeholder.png'
					alt='animation placeholder'
				/>
				{(state.offline || (timeOk !== null && timeOk !== true)) &&
					downloadDone && (
						<WarningStartingCheck
							timeOk={timeOk}
							setTimeOk={setTimeOk}
							offline={state.offline}
							setOffline={val =>
								dispatch({
									type: 'SET_OFFLINE',
									value: val === true,
								})
							}
						/>
					)}
			</div>
		</div>
	);
}
