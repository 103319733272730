import React, { useContext, useEffect } from 'react';
import { ScreenEnum } from '../../enums';
import { getUrl } from '../../utils/getUrl';
import AppStateContext from '../../AppStateProvider/context';
import { ValidationProps } from './interfaces';
import Waiting from '../../components/Waiting';

export default function Validation(props: ValidationProps) {
	const { state, dispatch } = useContext(AppStateContext);
	const auth = window.btoa(`ssp:${state.password}`);
	const url =
		getUrl(state.market, state.kvps, state.brand, state.url) +
		(props.checkDevice
			? `/module/content/bpca/depot/terminals/${state.device}/allcontents.json`
			: '/module/content/bpca/depot/terminals/terminals.csv');
	let fetchRequest = new Promise<string>(function (resolve, reject) {
		const xml = new XMLHttpRequest();
		xml.open('GET', url, true);
		if (auth) {
			xml.setRequestHeader('Authorization', 'Basic ' + auth);
		}
		xml.setRequestHeader('Service-Worker', 'false');
		xml.setRequestHeader('Cache-Control', 'max-age=0');
		xml.onload = function () {
			if (xml.status === 200) {
				resolve(xml.responseText);
			} else {
				resolve(xml.status.toString());
			}
		};
		xml.onerror = function (ev) {
			reject(ev);
		};
		xml.send();
	});

	useEffect(() => {
		if (props.checkDevice) {
			Promise.all([
				new Promise<void>(resolve => setTimeout(resolve, 2000)),
				fetchRequest,
			])
				.then(([_, data]: [void, string]) => {
					if (!/^\d{3}$/.test(data)) {
						props.setScreen(ScreenEnum.password);
					} else {
						dispatch({ type: 'SET_DEVICE', value: data });
						props.setScreen(ScreenEnum.selectDevice);
					}
				})
				.catch(reason => {
					console.log(
						'Catching reason and going back to device selection: ',
						reason
					);
					dispatch({ type: 'SET_DEVICE', value: '000' });
					props.setScreen(ScreenEnum.selectDevice);
				});
		} else {
			Promise.all([
				new Promise<void>(resolve => setTimeout(resolve, 2000)),
				fetchRequest,
			])
				.then(([_, data]: [void, string]) => {
					if (
						!/^\d{3}$/.test(data) &&
						data.split('\n')[0].includes('name') &&
						data.split('\n')[0].includes('id')
					) {
						const deviceLines = data?.split('\n').slice(1, -1);
						const devices: Array<string> = [];
						deviceLines?.forEach(line => {
							const params = line.split('\t');
							devices.push(params[2] + ' (' + params[1] + ')');
						});
						if (
							state.device &&
							devices.filter(d =>
								d.includes(`(${state.device || ''})`)
							).length === 0
						) {
							dispatch({ type: 'SET_DEVICE', value: null });
						}
						dispatch({ type: 'SET_DEVICES', value: devices });
						dispatch({
							type: 'SET_ERRORS',
							value: {
								...state.errors,
								passwordError: 0,
								urlError: 0,
								validationError: 0,
							},
						});
						props.setScreen(ScreenEnum.selectDevice);
					} else {
						dispatch({
							type: 'SET_ERRORS',
							value: {
								...state.errors,
								passwordError: data === '401' ? 1 : 0,
								urlError:
									data !== '401'
										? state.errors?.urlError + 1
										: 0,
							},
						});
						props.setScreen(ScreenEnum.selectMarket);
					}
				})
				.catch(reason => {
					console.error(
						'Catching reason and going back to market selection: ',
						reason
					);
					// if URL was set by user, tell him something is wrong with it
					if (state.url) {
						dispatch({
							type: 'SET_ERRORS',
							value: {
								...state.errors,
								urlError: state.errors?.urlError + 1,
							},
						});
					} else {
						dispatch({
							type: 'SET_ERRORS',
							value: {
								...state.errors,
								validationError:
									state.errors?.validationError + 1,
							},
						});
					}
					props.setScreen(ScreenEnum.selectMarket);
				});
		}
	}, []);

	return (
		<div className='validation'>
			{props.backBtn(
				props.checkDevice
					? ScreenEnum.selectDevice
					: ScreenEnum.selectMarket
			)}
			<Waiting
				screen={
					props.checkDevice
						? ScreenEnum.validationDevice
						: ScreenEnum.validation
				}
				setScreen={props.setScreen}
			/>
		</div>
	);
}
