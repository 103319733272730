import React, { useState } from 'react';
import { InputProps } from './interfaces';
import './styles.scss';

export default function Input(props: InputProps) {
	const {
		id,
		value,
		onBlur,
		labelId,
		labelHeadline,
		errorMessage,
		icon,
		...rest
	} = props;

	const newValue =
		props.type === 'url'
			? 'https://' +
			  value?.toString().replace('https://', '').replace('https:/', '')
			: value?.toString() || '';

	return (
		<label htmlFor={id} id={labelId} onClick={e => e.stopPropagation()}>
			{labelHeadline && <h4>{labelHeadline}</h4>}
			{errorMessage && <p className='error-message p2'>{errorMessage}</p>}
			<input
				{...rest}
				id={id}
				value={newValue}
				autoComplete='off'
				inputMode='none'
				onBlur={e => {
					if (props.type === 'url') {
						e.currentTarget.value =
							newValue === 'https://' ? '' : newValue;
					}
					if (onBlur) onBlur(e);
				}}
				onFocus={e => {
					if (props.type === 'url') e.currentTarget.value = newValue;
					dispatchEvent(
						new CustomEvent('keyboard-focus', {
							detail: {
								input: e.currentTarget,
							},
						})
					);
				}}
			/>
			{icon}
		</label>
	);
}
