import React, { useContext } from 'react';
import './styles.scss';
import { SelectDeviceProps } from './interfaces';
import Dropdown from '../../components/Dropdown';
import { ScreenEnum } from '../../enums';
import Progress from '../../components/Progress';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../AppStateProvider/context';
import RadioButtons from '../../components/RadioButtons';

export default function SelectDevice(props: SelectDeviceProps) {
	const { t } = useTranslation();
	const { state, dispatch } = useContext(AppStateContext);

	if (state.devices === null) {
		return <h1>{t('SelectDeviceNoDevices')}</h1>;
	}

	return (
		<>
			<div className='top'>
				<div className='select-device'>
					{props.backBtn(ScreenEnum.selectMarket)}
					<h1>{t('SelectDeviceHeadline')}</h1>
					{state.device && /^\d{3}$/.test(state.device) ? (
						<p className='p1 error-message'>
							{t('SelectDeviceError' + state.device)}
						</p>
					) : (
						<p className='p1'>{t('SelectDeviceText')}</p>
					)}
					<Progress
						screen={ScreenEnum.selectDevice}
						setScreen={props.setScreen}
					/>
					{state.devices.length > 0 ? (
						<label htmlFor='device'>
							<h4>{t('SelectDeviceLabelDevice')}</h4>
							<Dropdown
								list={state.devices}
								set={(selectedDevice: string) => {
									dispatch({
										type: 'SET_DEVICE',
										value: selectedDevice
											.split('(')[1]
											.split(')')[0],
									});
								}}
								defaultValue={state.devices.find(d =>
									d.includes(`(${state.device || ''})`)
								)}
								placeholder={t('SelectDevicePlaceholderDevice')}
							/>
						</label>
					) : (
						<h4>{t('SelectDevicesFoundNone')}</h4>
					)}
					{!state.apk && (
						<RadioButtons
							options={[
								{
									selected: !state.goToLabel,
									text: t('SetDeviceShowPricesheet'),
									value: false,
								},
								{
									selected: state.goToLabel,
									text: t('SetDeviceShowLabel'),
									value: true,
								},
							]}
							onClick={(value: boolean) =>
								dispatch({
									type: 'SET_GO_TO_LABEL',
									value: value,
								})
							}
						/>
					)}
				</div>
			</div>
			<div className='buttons'>
				<button
					className='white'
					disabled={state.device === null}
					onClick={() => props.setScreen(ScreenEnum.validationDevice)}
				>
					{t('ButtonConfirm')}
				</button>
				{props.cancelBtn}
			</div>
		</>
	);
}
