import { Close } from '../../assets/Icons';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export default function WarningOverlay(props: {
	headline: string;
	texts: Array<string | undefined>;
	btnFunction: MouseEventHandler<HTMLElement>;
	close?: MouseEventHandler<HTMLElement>;
	okBtn?: boolean;
}) {
	const { t } = useTranslation();

	return (
		<div className='warning-overlay-container' onClick={props.close}>
			<div>
				{!props.okBtn && (
					<button
						className='black unset circular'
						onClick={props.btnFunction}
					>
						{Close}
					</button>
				)}
				<h3>{t(props.headline)}</h3>
				{props.texts.map((text, index) => (
					<>
						{text && (
							<p
								className='p2'
								key={`${props.headline}-${text}-${index}`}
							>
								{t(text)}
							</p>
						)}
					</>
				))}
				{props.okBtn && (
					<button className='small red' onClick={props.btnFunction}>
						{t('Ok')}
					</button>
				)}
				{props.close && props.okBtn && (
					<button className='small black' onClick={props.close}>
						{t('Close')}
					</button>
				)}
			</div>
		</div>
	);
}
