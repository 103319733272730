import { SW_CACHE_READY } from './registerSW';

export function redirect(brand: string | null) {
	if (localStorage.getItem(SW_CACHE_READY)) {
		const params = new URLSearchParams(window.location.search);
		const orientation = params.get('orientation');
		const notRedesign = params.get('redesign') === 'false';

		const apk = params.get('apk') === 'true';
		const url = new URL(
			(
				(import.meta.env.VITE_REACT_APP_DEV_REDIRECT as string) ||
				(import.meta.env.VITE_REACT_APP_REDIRECT as string)
			)
				.replace('BRAND', brand || '')
				.replace('-redesign', notRedesign ? '-dev' : '-redesign')
		);
		if (orientation) url.searchParams.set('orientation', orientation);
		if (apk) url.searchParams.set('apk', 'true');

		window.location.replace(url);
	} else {
		setTimeout(() => redirect(brand), 500);
	}
}
