const project = '119c3a6b0142615995dac6bee43c8971';
const token = import.meta.env.VITE_REACT_APP_PHRASE_TOKEN as string;

export function setLanguage(
	lang: string,
	i18n: any,
	onDone?: (lang: string) => void,
	onError?: (lang: string) => void
) {
	fetch(
		`https://api.phrase.com/v2/projects/${project}/locales/${lang}/download?file_format=i18next`,
		{
			method: 'GET',
			headers: { Authorization: `token ${token}` },
		}
	)
		.then(response => {
			caches
				.open('did-webapp-phrase')
				.then(cache => cache.put(response.url, response));
			return response.clone().json();
		})
		.then(data => {
			i18n.addResourceBundle(lang, 'translation', data, true, true);
			if (onDone) onDone(lang);
		})
		.catch(error => {
			console.error(error);
			if (onError) onError(lang);
		});
}
